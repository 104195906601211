.popular {
  margin: 128px 0 0 0;

  .title {
    margin: 0 0 36px 0;
  }

  .body {
    display: flex;
    flex-wrap: wrap;
    gap: 18px;
    margin: 0 0 8px 0;
    transform: translateY(-110px);

    .longLine {
      max-width: 1107px;
      width: 100%;
      height: 1px;
      background-color: #e4e6e8;
    }

    .column:nth-child(1) {
      background-color: #44d078;
    }
    .column:nth-child(2) {
      background-color: #60b3ff;
    }
    .column:nth-child(1),
    .column:nth-child(2) {
      align-self: end;
      border: 1px solid transparent !important;

      .info {
        transform: translateY(88px);
        h3 {
          color: #fff !important;
        }
        p {
          opacity: 0;
          color: #fff !important;
          pointer-events: none;
        }
      }

      &:hover {
        .info {
          transform: translateY(0px);
        }
        p {
          opacity: 1;
          pointer-events: all;
        }
      }

      position: relative;
      .tallLine {
        position: absolute;
        background-color: #e4e6e8;
      }
      .tallLine {
        width: 1px;
        height: 879px;
      }
      .tallLine.first {
        top: 0;
        right: -10.5px;
      }
      .tallLine.second {
        top: 0;
        right: -10.5px;
      }
    }
    .column:nth-child(3) {
      border: none;
      padding: 0;
      border: none !important;
    }
    .column:nth-child(7),
    .column:nth-child(8),
    .column:nth-child(9) {
      align-self: start;
    }
    .column {
      flex: 0 1 25%;
      border-radius: 20px;
      padding: 34px 28px;
      border: 1px solid transparent;

      transition: all 0.3s;

      &:hover {
        border: 1px solid #2798ff;

        .more,
        .price {
          opacity: 1 !important;
          pointer-events: all !important;
        }
      }

      .item {
        cursor: pointer;

        .info {
          transition: all 0.3s;
          h3 {
            font-weight: 500;
            font-size: 21px;
            line-height: 114%;
            letter-spacing: -0.01em;
            margin: 12px 0;
            color: #222222;
          }
          p {
            transition: all 0.3s;
            font-size: 16px;
            line-height: 137%;
            color: #6a6f74;
          }
          .more,
          .price {
            transition: all 0.3s;
            opacity: 0;
            pointer-events: none;
          }
          .more {
            margin: 8px 0 14px 0;
            a {
              font-size: 18px;
              line-height: 133%;
              color: #2798ff;
              text-decoration: none;
            }
          }
          .price {
            display: flex;
            align-items: center;
            justify-content: space-between;

            p {
              font-size: 20px;
              line-height: 120%;
              letter-spacing: -0.01em;
              color: #222;
            }

            a {
              padding: 12px 43px;
              color: #fff;
              background-color: #222;
              border-radius: 16px;
              font-size: 17px;
              line-height: 141%;
              text-decoration: none;
            }
          }
        }
      }
    }
  }

  .allServices {
    transform: translateY(-110px);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;

    button {
      background-color: transparent;

      a {
        text-decoration: none;
        color: #6a6f74;
        font-size: 18px;
        line-height: 133%;
      }
    }
    img {
      transform: rotate(-90deg);
    }
  }
}

@media (max-width: 1118px) {
  .label {
    font-size: 36px;
  }
  .body {
    transform: translateY(-90px) !important;
  }
  .column {
    flex: 0 1 25.5% !important;

    .image img {
      width: 287px;
      height: 328px;
    }
  }
}
@media (max-width: 1088px) {
  .body {
    transform: translateY(-70px) !important;
  }
}

@media (max-width: 992px) {
  .popular {
    margin: 100px 0 0 0 !important;
  }
  .popularDesktop {
    display: none;
  }
}

@media (max-width: 624px) {
  .popular {
    margin: 72px 0 0 0 !important;
  }
}
