.ourTeam {
  margin: 80px 0 0 0;

  .container {
    max-width: 1095px;
    margin: 0 auto;
    padding: 0 15px;
  }

  .label {
    margin: 0 0 16px 0;
  }
  .text {
    margin: 0 0 12px 0;
    font-size: 20px;
    line-height: 120%;
    letter-spacing: -0.01em;
    color: #6a6f74;
  }

  .body {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;

    .column {
      flex: 0 1 23%;

      .item {
        text-align: center;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .name {
          margin: 12px 0 8px 0;
          font-weight: 500;
          font-size: 24px;
          line-height: 125%;
          letter-spacing: -0.01em;
          color: #222;
        }
        .description {
          font-size: 16px;
          line-height: 137%;
          color: #6a6f74;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .ourTeam {
    margin: 60px 0 0 0 !important;
  }

  .body {
    .column {
      flex: 0 1 30% !important;
    }
  }
}

@media (max-width: 768px) {
  .body {
    .column {
      flex: 0 1 48% !important;

      .name {
        font-size: 21px !important;
      }
    }
  }
}

@media (max-width: 479px) {
  .ourTeam {
    margin: 40px 0 0 0 !important;
  }
  .body {
    .column {
      flex: 1 1 100% !important;
    }
  }
}
