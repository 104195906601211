.questions {
  .label {
    margin: 128px 0 36px 0;
  }

  .body {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    .left {
      flex: 1 1 50%;
    }

    .right {
      flex: 0 1 43%;
    }

    .left {
      > *:not(:last-child) {
        margin: 0 0 8px 0;
      }
    }

    .right {
      display: flex;
      justify-content: flex-end;
      .sended {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h2 {
          margin: 24px 0 12px 0;
        }
        p {
          max-width: 378px;
          margin: 0 auto;
          margin: 12px 0 24px 0;
          font-size: 18px;
          line-height: 133%;
          text-align: center;
          color: #6a6f74;
        }

        button {
          background-color: #222 !important;
        }
      }

      .form,
      .sended {
        max-width: 434px;
        padding: 36px 28px;
        background-color: #f5f6f8;
        border-radius: 20px;

        h2 {
          font-weight: 700;
          font-size: 28px;
          line-height: 121%;
          letter-spacing: -0.01em;
          text-align: center;
          color: #222;
        }
        .text {
          max-width: 445px;
          margin: 0 auto;
          p {
            margin: 12px 0 24px 0;
            font-size: 18px;
            line-height: 133%;
            text-align: center;
            color: #6a6f74;
          }
        }
      }
      .field {
        input,
        textarea {
          width: 90%;
          border-radius: 16px;
          padding: 16px 10px 16px 28px;
          font-size: 17px;
          line-height: 141%;
          outline: none;
          border: 1px solid transparent;

          &:focus {
            border: 1px solid #9da0a4;
          }
        }
        textarea {
          resize: none;
          min-height: 120px;
        }

        p {
          color: #ff4f35;
          margin: 8px 0 0 0;
        }
      }
      .field:not(:last-child) {
        margin: 0 0 12px 0;
      }
      .field.incorrect {
        input {
          border: 1px solid #ff4f35;
        }
      }

      h3 {
        font-size: 16px;
        line-height: 137%;

        span,
        a {
          text-decoration: none;
          color: #6a6f74;
          display: block;
        }
        a {
          color: #222;
        }
      }

      .submit,
      .sended button {
        width: 100%;
        margin: 24px 0 0 0;
        display: flex;
        justify-content: center;
        background-color: #8c8c8d;
        border-radius: 16px;
        transition: 0.3s all;
        cursor: pointer;

        padding: 16px;
        color: #fff;
        font-size: 18px;
        line-height: 133%;
      }
      .submit.fullfilled {
        background-color: #222;
      }
    }
  }
}

@media (max-width: 992px) {
  .questions {
    .label {
      margin: 100px 0 36px 0 !important;
    }
  }
  .right {
    .form {
      padding: 20px 16px !important;

      .field {
        input,
        textarea {
          width: 88% !important;
        }
      }
    }
  }
}

@media (max-width: 742px) {
  .body {
    flex-wrap: wrap;

    .left,
    .right {
      flex: 1 1 100% !important;
    }

    .form {
      max-width: 100% !important;
    }
  }
}

@media (max-width: 479px) {
  .questions {
    .label {
      margin: 56px 0 16px 0 !important;
    }
  }

  .right {
    h2 {
      font-size: 25px !important;
    }
  }
}
