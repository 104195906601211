.papers {
  margin: 80px 0;

  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 15px 20px;

    .content {
      background-color: #f5f6f8;
      border-radius: 24px;
      padding: 48px;

      .label {
        margin: 0 0 36px 0;
      }

      .body {
        &.active {
          transition: 0.3s all;
          max-height: 1000px;

          .column:nth-child(4) {
            opacity: 1;
            pointer-events: all;
          }
        }
        transition: 0.3s all;
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        max-height: 404px;

        .column:nth-child(4) {
          opacity: 0;
          pointer-events: none;
        }

        .column {
          display: flex;
          cursor: pointer;
          flex: 0 1 31%;
          border: 2px solid transparent;
          border-radius: 20px;
          transition: 0.3s all;

          &:hover,
          &:focus {
            border: 2px solid #ffb74a;
            box-shadow: 0 4px 15px 0 rgba(95, 105, 131, 0.12), 0 5px 4px 0 rgba(95, 105, 131, 0.05),
              0 0 4px 0 rgba(95, 105, 131, 0.07);
          }

          &:hover,
          &:focus {
            .more,
            .buy {
              opacity: 1 !important;
              pointer-events: all !important;
            }
          }

          .item {
            display: flex;
            flex-direction: column;
            background-color: #fff;
            border-radius: 20px;
            padding: 28px;

            .name {
              font-weight: 500;
              font-size: 24px;
              line-height: 125%;
              letter-spacing: -0.01em;
              color: #222;
            }
            .text {
              margin: 8px 0;
              font-size: 18px;
              line-height: 133%;
              color: #6a6f74;
            }
            a {
              text-decoration: none;
            }
            .more,
            .buy {
              transition: 0.3s all;
              opacity: 0;
              pointer-events: none;
            }
            .more {
              margin: 0 0 46px 0;
              flex: 1 1 auto;
              font-size: 18px;
              line-height: 133%;
              color: #2798ff;
            }
            .buy {
              display: flex;
              gap: 16px;
              justify-content: space-between;
              align-items: center;
              p {
                font-size: 20px;
                line-height: 120%;
                letter-spacing: -0.01em;
                color: #222;
              }
              .button {
                background-color: #222;
                border-radius: 16px;
                padding: 12px 45px;

                button {
                  background-color: transparent;
                  color: #fff;
                  font-size: 17px;
                  line-height: 141%;
                }
              }
            }
          }
        }
      }
      .dropdowns {
        display: none;

        .item {
          display: flex;
          justify-content: space-between;
          background-color: #fff;
          align-items: center;
          padding: 20px 16px;

          svg {
            min-width: 36px;
          }

          h2 {
            font-size: 21px;
            line-height: 114%;
            letter-spacing: -0.01em;
          }
        }
      }
      .all {
        display: flex;
        justify-content: center;

        &.active {
          button svg {
            transform: rotate(180deg);
          }
        }
        svg {
          transition: 0.3s all;
        }
        button {
          display: flex;
          gap: 4px;
          align-items: center;
          background-color: transparent;

          font-size: 18px;
          line-height: 133%;
          color: #6a6f74;
        }
      }
    }
  }
}

@media (max-width: 1199px) {
  .name {
    font-size: 20px !important;
  }
  .text {
    font-size: 16px !important;
  }
  .buy {
    p {
      font-size: 18px !important;
    }
    .button {
      padding: 12px 35px !important;

      button {
        font-size: 15px !important;
      }
    }
  }
}

@media (max-width: 1024px) {
  .content {
    padding: 32px !important;
  }
  .more {
    opacity: 1 !important;
    pointer-events: all !important;
  }
  .buy {
    p {
      font-size: 16px !important;
    }
    .button {
      padding: 12px 25px !important;

      button {
        font-size: 15px !important;
      }
    }
  }
}

@media (max-width: 992px) {
  .papers {
    margin: 60px 0 !important;
  }
}

@media (max-width: 991.98px) {
  .buy {
    p {
      font-size: 18px !important;
    }
    .button {
      padding: 12px 35px !important;

      button {
        font-size: 17px !important;
      }
    }
  }
  .container {
    padding: 15px 0 !important;
  }
  .body {
    max-height: 100% !important;
  }
  .column {
    &:nth-child(4) {
      opacity: 1 !important;
      pointer-events: all !important;
    }
    flex: 0 1 48% !important;
  }
  .all {
    display: none !important;
  }
}

@media (max-width: 710px) {
  .content {
    padding: 16px !important;
  }
  .body {
    display: none !important;
  }
  .dropdowns {
    display: block !important;

    > *:not(:last-child) {
      margin: 0 0 8px 0;
    }
  }
}

@media (max-width: 479px) {
  .papers {
    margin: 40px 0 !important;
  }
}
