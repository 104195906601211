.consult {
  .container {
    margin: 0 auto;
    padding: 0 20px;
    max-width: 1200px;
  }

  .body {
    background-color: #f5f6f8;
    border-radius: 24px;
    padding: 15px 48px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .left,
    .right {
      flex: 0 1 48%;
    }

    .left {
      .title {
        font-weight: 700;
        font-size: 34px;
        line-height: 112%;
        letter-spacing: -0.01em;

        color: #222;
      }
      .text {
        margin: 12px 0 16px 0;
        font-size: 18px;
        line-height: 133%;

        color: #6a6f74;
      }
      .button {
        margin: 36px 0 0 0;
        max-width: 300px;
        min-height: 56px;
        background-color: #222;
        border-radius: 16px;
        text-decoration: none;

        display: flex;
        align-items: center;
        justify-content: center;
        button {
          background-color: transparent;
          color: #fff;

          font-size: 18px;
          line-height: 133%;
        }
      }
    }
    .right {
      .image {
        transform: translateY(15px);
        svg {
          max-width: 100%;
          max-height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .left {
    flex: 1 1 50% !important;
  }
  .right {
    .image {
      transform: translateY(40px) !important;
    }
  }
}

@media (max-width: 958px) {
  .container {
    padding: 0 !important;
  }
  .title {
    font-size: 28px !important;
  }
  .text {
    font-size: 16px !important;
  }
}

@media (max-width: 768px) {
  .right {
    .image {
      transform: translateY(65px) !important;
    }
  }
}

@media (max-width: 767px) {
  .consult {
    display: none;
  }
}
