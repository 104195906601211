.header.scrolled {
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(8px);
}
.header.scrolled.active {
  background-color: #fff;
  .body {
    backdrop-filter: none;
  }
}
.header {
  transition: 0.3s all;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 50;
  background-color: #fff;

  .body {
    padding: 16px 0;

    display: flex;
    justify-content: space-between;
    align-items: center;

    .left img {
      width: 140px;
    }

    .right {
      display: flex;
      align-items: center;
      gap: 20px;

      .consult {
        background-color: #f5f6f8;
        a {
          color: #000;
        }
      }
      .personal {
        background-color: #222222;
        a {
          color: #fff;
        }
      }

      .consult,
      .personal {
        cursor: pointer;
        padding: 12px 24px;
        border-radius: 16px;
        a {
          text-decoration: none;
          font-size: 18px;
          line-height: 133%;
        }
      }

      .burger {
        cursor: pointer;
        width: 24px;
        height: 20px;
        position: relative;

        &::before,
        &::after {
          content: "";
        }

        &::before,
        &::after {
          position: absolute;
          left: 0;
          width: 100%;
          height: 3px;
          background-color: #000;
          border-radius: 30px;
          transition: all 0.3s;
        }

        &::before {
          top: 0;
        }
        &::after {
          bottom: 0;
        }

        span {
          position: absolute;
          top: 8.5px;
          left: 0;
          width: 100%;
          height: 3px;
          background-color: #000;
          border-radius: 10px;
          transition: all 0.3s;
        }

        &.active::before {
          top: 9px;
          transform: rotate(45deg);
        }
        &.active span {
          transform: rotate(45deg);
          opacity: 0;
        }
        &.active::after {
          bottom: 9px;
          transform: rotate(-45deg);
        }
      }
    }
  }

  .menuPc,
  .menuTablet,
  .menuMobile {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    z-index: 50;
    top: -1000px;
    left: 0;
    opacity: 0;
    pointer-events: none;

    &.active {
      top: 80px;
      opacity: 1;
      pointer-events: all;
    }

    .menuContent {
      background-color: #fff;
      border-bottom-left-radius: 24px;
      border-bottom-right-radius: 24px;
      max-width: 1200px;
      padding: 48px;
      margin: 0 auto;

      display: flex;
      justify-content: space-between;

      .services,
      .dropdownContent {
        .title {
          display: inline-flex;
          flex-direction: column;
          gap: 12px;
          font-size: 17px;
          line-height: 141%;
          margin: 0 0 14px 0;
          color: #222;
          text-decoration: none;

          img {
            width: 26px;
            height: 26px;
          }
        }

        .item,
        .link {
          width: 200px;
          margin: 0 0 8px 0;
          text-decoration: none;

          a,
          p,
          div {
            padding: 0 !important;
            font-size: 16px;
            line-height: 137%;
            text-decoration: none;
            color: #6a6f74;
          }
          div {
            cursor: pointer;
          }
          a:hover,
          div:hover {
            text-decoration: underline;
            color: #000;
          }

          .medias {
            display: flex;
            align-items: center;
            gap: 6px;

            > * {
              cursor: pointer;
            }

            svg {
              color: #9da0a4;
              transition: color 0.3s;
              &:hover {
                color: #222222;
              }
            }
          }
        }
        .link {
          a {
            color: #2798ff;

            &:hover {
              text-decoration: underline !important;
              color: #2798ff !important;
            }
          }
        }
      }
    }
  }

  .menuTablet {
    display: none;
  }

  .menuTablet {
    .row {
      display: flex;
      gap: 16px;

      .divider {
        display: flex;
        gap: 16px;
      }

      .buttonsTablet {
        display: flex;
        flex-direction: column;
        gap: 16px;
        border-right: 1px solid #e4e6e8;

        .option {
          width: 285px;
          display: flex;
          align-items: center;
          gap: 8px;
          background-color: transparent;
          font-size: 18px;
          line-height: 133%;
          padding: 16px 0;
          position: relative;

          &:hover,
          &.active {
            background-color: #f5f6f8;
          }
          &.active {
            &:after {
              content: "";
              position: absolute;
              right: -1px;
              width: 2px;
              height: 100%;
              background-color: #2798ff;
            }
          }

          img {
            width: 26px;
            height: 26px;
          }
        }
      }
    }
  }
  .menuMobile {
    display: none;
    overflow: auto;

    .dropdowns {
      width: 100vw;
      .dropdown {
        display: flex;
        flex-direction: column;
        .dropdownBtn {
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          padding: 8px 0;
          border-bottom: 1px solid #e4e6e8;

          h2,
          a {
            text-decoration: none;
            color: #222;
            img {
              width: 26px;
            }
            display: flex;
            align-items: center;
            gap: 8px;
            font-size: 18px;
            line-height: 133%;
          }

          .arrow {
            transition: all 0.3s;
          }
          .arrow.active {
            transform: rotate(180deg);
          }
        }

        .dropdownContent {
          padding: 0 0 0 36px;
        }

        .buttons {
          display: flex;
          flex-direction: column;
          gap: 24px;
        }
        .hiddenConsult {
          background-color: #f5f6f8;
        }
        .hiddenPersonal {
          background-color: #222222;
          color: #fff;
        }
        .hiddenConsult,
        .hiddenPersonal {
          padding: 16px;
          border-radius: 16px;
          font-size: 18px;
          line-height: 133%;
        }
      }
    }
  }
}
@media (min-width: 1200px) {
  .body.active {
    transform: translate(-8px);
  }
}
@media (max-width: 1200px) {
  .menuContent {
    padding: 32px !important;
  }
  .menuPc {
    display: none;
  }
  .menuTablet {
    display: block !important;
  }
  .item,
  .link {
    width: 285px !important;
    padding: 8px 0 8px 0;
    margin: 0 !important;
  }
}

@media (max-width: 992px) {
  .menuContent {
    padding: 32px 32px 64px 24px !important;
  }
  .menuTablet {
    display: none !important;
  }
  .menuMobile {
    display: block !important;

    &.active {
      top: 55px !important;
    }
  }

  .consult,
  .personal {
    display: none;
    pointer-events: none;
  }
}

@media (max-width: 767px) {
  .header {
    .body {
      transition: 0.3s all;
      &.active {
        transform: translateY(-15px);
      }
    }
  }
}

@media (max-width: 360px) {
  .arrow {
    padding: 0 15px 0 0;
  }
}
