.popularMobile {
  display: none;
}

@media (max-width: 624px) {
  main {
    margin: 100px 0 0 0 !important;
  }
  .popularMobile {
    display: block !important;
    margin: 0 0 40px 0;

    .label {
      margin: 0 0 16px 0;
    }

    .item:not(:last-child) {
      margin: 0 0 8px 0;
    }

    hr {
      width: 100%;
      height: 1px;
      background-color: #e4e6e8;
    }

    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 16px;

      svg {
        min-width: 36px;
      }

      h2 {
        font-size: 21px;
        line-height: 114%;
        letter-spacing: -0.01em;
      }
    }

    .item.green {
      background-color: #44d078;
      color: #fff;
      border-radius: 16px;
      h2 {
        font-weight: 500;
      }
    }
    .item.blue {
      h2 {
        font-weight: 500;
      }
      background-color: #60b3ff;
      color: #fff;
      border-radius: 16px;
    }

    .allServices {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;

      button {
        background-color: transparent;

        a {
          text-decoration: none;
          color: #6a6f74;
          font-size: 18px;
          line-height: 133%;
        }
      }
      img {
        transform: rotate(-90deg);
      }
    }
  }
}
