.popularTablet {
  display: none;
}
@media (max-width: 992px) {
  .popularTablet {
    display: block !important;
    margin: 0 0 80px 0;
  }
  .body {
    display: flex;
    align-items: flex-end;
    gap: 16px;
    position: relative;
  }
  .tallLine {
    position: absolute;
    top: 0;
    left: 51%;
    width: 1px;
    height: 100%;
    background-color: #e4e6e8;
  }
  .left {
    flex: 0 1 50%;
    .label {
      margin: 0 0 36px 0 !important;
    }

    > *:not(:last-child) {
      margin: 0 0 16px 0;
    }
    > *:nth-child(2) {
      background-color: #44d078;
    }
    > *:nth-child(3) {
      background-color: #60b3ff;
    }
    > *:nth-child(2),
    > *:nth-child(3) {
      padding: 82px 28px 28px 28px;
      border-radius: 20px;
      h3,
      p {
        color: #fff;
      }
    }
    > *:nth-child(4) {
      border-top: 1px solid #e4e6e8;
      transform: translate(16px, 4px);
    }
    > *:nth-child(5) {
      border-top: 1px solid #e4e6e8;
      transform: translate(16px, 28px);
    }
  }

  .right {
    flex: 0 1 50%;
    transform: translateY(-18px);

    > *:nth-child(2),
    > *:nth-child(3),
    > *:nth-child(4),
    > *:nth-child(5) {
      border-top: 1px solid #e4e6e8;
    }
    > *:nth-child(1) {
      margin: 0 0 36px 0;
      .image {
        transform: translateX(-15px);
        max-width: 343px;
        max-height: 212px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    > *:not(:first-child) {
      transform: translateY(-50px);
    }
    > *:nth-child(4) {
      transform: translateY(10px);
    }
    > *:nth-child(5) {
      transform: translateY(23px);
    }
  }
  .item {
    padding: 28px;

    h3 {
      font-weight: 500;
      font-size: 21px;
      line-height: 114%;
      letter-spacing: -0.01em;
      margin: 12px 0;
      color: #222222;
    }
    p {
      max-width: 287px;
      transition: all 0.3s;
      font-size: 16px;
      line-height: 137%;
      color: #6a6f74;
    }
    .more {
      margin: 8px 0 0 0;
      a {
        font-size: 18px;
        line-height: 133%;
        color: #2798ff;
        text-decoration: none;
      }
    }
  }

  .allServices {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    margin: 16px 0 0 0;

    button {
      background-color: transparent;

      a {
        text-decoration: none;
        color: #6a6f74;
        font-size: 18px;
        line-height: 133%;
      }
    }
    img {
      transform: rotate(-90deg);
    }
  }
}

@media (max-width: 768px) {
  .item {
    h3 {
      font-size: 21px !important;
    }
  }

  .right {
    transform: translateY(-43px) !important;
  }

  .left {
    > *:nth-child(4) {
      transform: translate(16px, 25px) !important;
    }
    > *:nth-child(5) {
      transform: translate(16px, 23px) !important;
    }
  }
}
@media (max-width: 624px) {
  .popularTablet {
    display: none !important;
  }
}
