.steps {
  margin: 128px 0 0 0;

  .container {
    margin: 0 auto;
    padding: 0 20px;
    max-width: 1200px;
  }

  .label {
    text-align: center;
    margin: 0 0 36px 0;
  }

  .body {
    display: flex;
    gap: 16px;

    .column {
      flex: 0 1 24%;

      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        .icon {
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .stepName {
          margin: 24px 0 12px 0;
          font-weight: 500;
          font-size: 24px;
          line-height: 125%;
          letter-spacing: -0.01em;
          color: #222;
        }
        .text {
          max-width: 254px;
          font-size: 18px;
          line-height: 133%;
          color: #6a6f74;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .steps {
    margin: 100px 0 0 0 !important;

    .stepName {
      font-size: 21px !important;
    }
    .text {
      font-size: 16px !important;
    }
  }
}

@media (max-width: 922px) {
  .container {
    padding: 0px 15px !important;
  }
  .body {
    flex-wrap: wrap;

    .column {
      flex: 1 1 48% !important;

      .text {
        max-width: 100% !important;
      }
    }
  }
}

@media (max-width: 479px) {
  .steps {
    margin: 72px 0 0 0 !important;
    .body {
      .column {
        flex: 1 1 100% !important;

        .item {
          flex-direction: row !important;
          align-items: start !important;
          justify-content: start !important;
          text-align: left !important;
          gap: 11px;
          .icon {
            transform: translateY(24px);
            img {
              width: 70px !important;
              height: 70px !important;
              object-fit: cover;
            }
          }
        }
      }
    }
  }
}
