.body {
  margin: 100px 0 0 0;
  display: flex;
  justify-content: space-between;
  padding: 0 0 48px 0;

  .left {
    max-width: 524px;

    .list {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      margin: 0 0 26px 0;

      .item {
        a {
          color: #6a6f74;
          text-decoration: none;
        }
        cursor: pointer;
        font-size: 16px;
        line-height: 137%;
        color: #6a6f74;

        &:hover {
          text-decoration: underline;
          color: #000;
        }
      }
    }

    .policy {
      text-decoration: none;
      cursor: pointer;
      margin: 8px 0 0 0;
      font-size: 15px;
      color: #9da0a4;

      &:hover {
        text-decoration: underline;
      }
    }
  }
  .right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .medias {
      margin: 0 0 13px 0;
      display: flex;
      gap: 8px;

      svg {
        cursor: pointer;
        color: #9da0a4;
        transition: color 0.3s;
        &:hover {
          color: #222222;
        }
      }
    }
    .date,
    .phone {
      font-size: 16px;
      line-height: 137%;
      color: #6a6f74;
    }
    .phone {
      margin: 8px 0 16px 0;
      flex: 1 1 auto;
    }
    .author {
      font-size: 15px;
      line-height: 147%;
      color: #9da0a4;
    }
  }
}
.hiddenElements {
  display: none;
}

@media (max-width: 992px) {
  .left {
    max-width: 336px !important;
  }
}

@media (max-width: 768px) {
  .list {
    .item {
      a {
        padding: 0 !important;
      }
    }
  }
}

@media (max-width: 662px) {
  .body {
    flex-direction: column-reverse;
    align-items: start !important;
    justify-content: start !important;
    background-color: #f5f6f8;
    padding: 16px;
    border-radius: 16px;

    .left {
      .list {
        flex-direction: column;
      }

      .policy {
        display: none;
      }
    }

    .right {
      justify-content: start !important;
      align-items: start !important;
      margin: 0 0 24px 0;

      .author {
        display: none;
      }
    }
  }
  .hiddenElements {
    display: block !important;
    margin: 8px 0 32px 0;

    > *:not(:last-child) {
      margin: 0 0 8px 0;
    }

    .hiddenAuthor {
      color: #9da0a4;
      font-size: 15px;
      line-height: 147%;
    }
  }
}
