table {
  position: relative;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;

  .tableHeader {
    position: sticky;
    top: -10px;
    left: 0;
    width: 100%;
    height: 56px;
    z-index: 1;
    background-color: #fff;

    th:first-child {
      text-align: left;
    }
  }

  @media (max-width: 768px) {
    .tableHeader {
      top: -30px;
    }
  }

  th,
  td {
    font-size: 17px;
    line-height: 141%;
    color: #222;
    font-weight: 400;
  }
  th.hovered,
  td.hovered {
    background-color: #f5f6f8;
  }

  td,
  th {
    border-bottom: 1px solid #e4e6e8;
    border-right: 1px solid #e4e6e8;
    position: relative;
  }

  tr {
    height: 56px;

    &:last-child td,
    &:last-child th {
      border-bottom: none;
    }
  }

  td {
    width: 180px;
    text-align: center;
    padding: 16px 8px;

    &:first-child {
      text-align: left;
    }

    &:not(:first-child) {
      max-width: 100px;
    }

    span {
      color: #6a6f74;
    }
  }

  td:last-child,
  th:last-child {
    border-right: none;
  }
}

@media (max-width: 712px) {
  table,
  .dropdowns {
    display: none;
  }

  .table {
    display: block !important;
    max-width: 340px;

    .title {
      display: flex;
      align-items: start;
      justify-content: space-between;
      max-width: 300px !important;
      font-weight: 700;
      font-size: 14px;
      line-height: 129%;
      color: #222;

      img {
        width: 20px !important;
        transform: translateX(25px);
      }
    }
    .name {
      margin: 16px 0;
      font-size: 16px;
      line-height: 137%;
      color: #222;
    }

    .column {
      &.dropdownOpt {
        margin: 25px 0 0 0;
      }
    }

    .row {
      margin: 16px 0;
      display: flex;
      align-items: center;
      gap: 6px;
      min-height: 42px;

      .divider {
        width: 1px;
        height: 50px;
        background-color: #e4e6e8;
      }
      .divider.bigger {
        height: 60px;
      }

      .item {
        width: 100px;

        display: flex;
        justify-content: center;
      }

      p {
        word-wrap: break-word;
        font-size: 14px;
        line-height: 129%;
        text-align: center;
        color: #222;
      }
    }
    .consultModal {
      box-shadow: 0 4px 15px 0 rgba(95, 105, 131, 0.12), 0 5px 4px 0 rgba(95, 105, 131, 0.05), 0 0 4px 0 rgba(95, 105, 131, 0.07);
      background-color: #fff;
      padding: 16px;
      margin: 22px 0;
      position: relative;
      max-width: 296px;

      .closeIcon {
        position: absolute;
        right: 10px;
        top: 10px;
      }

      .consultTitle {
        margin: 0 0 8px 0;
        font-size: 14px;
        line-height: 129%;
        color: #222;
      }
      .consultText {
        font-size: 14px;
        line-height: 129%;
        color: #6a6f74;
      }
    }
  }
}

.modal {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);

  overflow: auto;
  position: fixed;
  z-index: 2000;

  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: start;
  padding: 10px 0 0 0;

  opacity: 0;
  pointer-events: none;

  transition: 0.3s all;

  &.active {
    transition: 0.3s all;
    opacity: 1;
    pointer-events: all;
  }

  .modalContent {
    &.more {
      background-color: #fff;
      padding: 48px;
      max-width: 972px;
      // !!!
      margin: 0 0 120px 0;

      .closeDesktop,
      .close {
        right: 15px;
        top: 15px;
      }
    }

    max-width: 500px;
    background-color: #f5f6f8;
    padding: 36px 28px;
    margin: 16px 26px 16px 16px;
    border-radius: 20px;
    position: relative;

    .close {
      display: none;
    }

    .closeDesktop,
    .close {
      cursor: pointer;
      position: absolute;
      right: -55px;
      top: 0;
      z-index: 2;
    }
    .sended {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      h2 {
        margin: 24px 0 12px 0;
      }
      p {
        max-width: 378px;
        margin: 0 auto;
        margin: 12px 0 24px 0;
        font-size: 18px;
        line-height: 133%;
        text-align: center;
        color: #6a6f74;
      }

      button {
        background-color: #222 !important;
        width: 100%;
        margin: 24px 0 0 0;
        display: flex;
        justify-content: center;
        background-color: #8c8c8d;
        border-radius: 16px;
        transition: 0.3s all;
        cursor: pointer;

        padding: 16px;
        color: #fff;
        font-size: 18px;
        line-height: 133%;
      }
    }
    .form,
    .sended {
      h2 {
        font-weight: 700;
        font-size: 28px;
        line-height: 121%;
        letter-spacing: -0.01em;
        text-align: center;
        color: #222;
      }
      .text {
        max-width: 445px;
        margin: 0 auto;
        p {
          margin: 12px 0 24px 0;
          font-size: 18px;
          line-height: 133%;
          text-align: center;
          color: #6a6f74;
        }
      }
      .field {
        input,
        textarea {
          width: 90%;
          border-radius: 16px;
          padding: 16px 10px 16px 28px;
          font-size: 17px;
          line-height: 141%;
          outline: none;
          border: 1px solid transparent;

          &:focus {
            border: 1px solid #9da0a4;
          }
        }
        textarea {
          resize: none;
          min-height: 120px;
        }

        p {
          color: #ff4f35;
          margin: 8px 0 0 0;
        }
      }
      .field:not(:last-child) {
        margin: 0 0 12px 0;
      }
      .field.incorrect {
        input {
          border: 1px solid #ff4f35;
        }
      }

      h3 {
        font-size: 16px;
        line-height: 137%;

        color: #222;

        span,
        a {
          text-decoration: none;
          color: #6a6f74;
          display: block;
        }
        a {
          color: #222;
        }
      }

      .submit,
      .sended button {
        width: 100%;
        margin: 24px 0 0 0;
        display: flex;
        justify-content: center;
        background-color: #8c8c8d;
        border-radius: 16px;
        transition: 0.3s all;
        cursor: pointer;

        padding: 16px;
        color: #fff;
        font-size: 18px;
        line-height: 133%;
      }
      .submit.fullfilled {
        background-color: #222;
      }
    }

    .moreMode {
      &.orderActive {
        opacity: 0;
      }

      .body {
        display: flex;
        gap: 16px;
        margin: 0 0 36px 0;

        .left {
          flex: 0 1 48%;
          img {
            max-width: 100%;
            max-height: 100%;
            object-fit: cover;
            border-radius: 20px;
          }
        }
        .right {
          flex: 0 1 48%;
          display: flex;
          flex-direction: column;
          h2 {
            font-weight: 700;
            font-size: 28px;
            line-height: 121%;
            letter-spacing: -0.01em;
            color: #222;
          }
          h3 {
            margin: 16px 0;
            font-size: 18px;
            line-height: 133%;
            color: #6a6f74;
          }
          p {
            font-size: 20px;
            line-height: 120%;
            letter-spacing: -0.01em;
            color: #222;
            flex: 1 1 auto;
          }

          .compare {
            background-color: #f5f6f8 !important;
            button {
              color: #222 !important;
            }
          }
          .buy,
          .compare {
            margin: 8px 0 0 0;
            cursor: pointer;
            display: flex;
            justify-content: center;
            width: 100%;
            padding: 16px;
            background-color: #222;
            border-radius: 16px;

            button {
              font-size: 18px;
              line-height: 133%;
              color: #fff;
              background-color: transparent;
            }
          }
        }
      }
      .info {
        margin: 0 0 36px 0;
        h2 {
          font-size: 20px;
          line-height: 120%;
          letter-spacing: -0.01em;
          color: #222;
          margin: 0 0 12px 0;
        }
        p {
          font-size: 17px;
          line-height: 141%;
          color: #6a6f74;
        }
      }
      .includes {
        margin: 0 0 36px 0;
        h2 {
          font-size: 20px;
          line-height: 120%;
          letter-spacing: -0.01em;
          color: #222;
          margin: 0 0 12px 0;
        }
        li {
          font-weight: 400;
          font-size: 17px;
          line-height: 141%;
          color: #6a6f74;
        }
      }
      .moreInfo {
        font-size: 15px;
        line-height: 147%;
        color: #6a6f74;
        margin: 36px 0;
      }
      .otherServices {
        font-size: 16px;
        line-height: 137%;
        color: #2798ff;
        text-decoration: none;
      }
    }

    &.compare {
      max-width: 1000px;
      width: 100%;
      background-color: #fff;

      @media (max-width: 712px) {
        max-width: none !important;
        width: auto !important;
      }
    }
  }
}

.olderModal {
  max-width: 500px;
}

.table {
  display: none;
}

@media (max-width: 1180px) {
  .modalContent.compare {
    .closeDesktop {
      // z-index: 5;
      right: 10px !important;
      top: 7px !important;

      svg {
        width: 36px;
      }
    }
  }
}

@media (max-width: 992px) {
  .tableImg {
    max-width: 832px !important;
    .desktop {
      display: none;
    }
    .tablet {
      display: block !important;
    }
  }
}

@media (max-width: 768px) {
  .tableImg {
    .tablet {
      display: none !important;
    }
    .mobile {
      display: block !important;
    }
  }
  .modal {
    padding: 30px 0 0 0 !important;
  }
  .modalContent {
    padding: 20px 16px !important;
    // transform: translateY(50px);
    &.more {
      padding: 40px 32px !important;
    }
    &.compare {
      transform: none !important;
    }
  }
  .closeDesktop {
    display: none;
  }
  .close {
    display: block !important;
    z-index: 3;
    right: 10px !important;
    top: -20px !important;
  }

  .form,
  .sended {
    h2 {
      font-size: 25px !important;
    }
  }
  h3 {
    font-size: 15px !important;
  }

  .moreMode {
    .body {
      .left {
        img {
          min-width: 312px;
          min-height: 260px;
          border-radius: 20px;
        }
      }
      .right {
        .buy {
          width: 94% !important;
        }
      }
    }
  }
}

@media (max-width: 750px) {
  .close {
    display: block !important;
    z-index: 3;
    right: 10px !important;
    top: 10px !important;
  }
  .tableImg {
    display: none;
  }
}

@media (max-width: 688px) {
  .moreMode {
    .body {
      flex-wrap: wrap;

      .left,
      .right {
        flex: 1 1 100% !important;
      }

      .right {
        p {
          margin: 8px 0 16px 0;
        }
        h3 {
          font-size: 18px !important;
        }
      }
    }
  }
}

@media (max-width: 479px) {
  .modalContent {
    &.compare {
      margin: 0;
    }
  }
  .modalContent {
    &.more {
      padding: 16px !important;
    }

    .moreMode {
      .body {
        .right {
          .buy,
          .compare {
            width: 90% !important;
          }
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .close {
    display: block !important;
    z-index: 3;
    right: -5px !important;
    top: -10px !important;
  }
}
