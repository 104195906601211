.services {
  .content {
    padding: 48px 48px 48px 48px;
    border-radius: 24px;
    background-color: #f5f6f8;

    .title {
      font-weight: 700;
      font-size: 52px;
      line-height: 104%;
      letter-spacing: -0.01em;
      color: #222222;
    }

    .body {
      margin: 36px 0 0 0;
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      position: relative;

      .divider {
        width: 1px;
        background-color: #e4e6e8;
      }
      .lineDivider {
        position: absolute;
        bottom: 50%;
        left: 0;
        width: 753.5px;
        height: 1px;
        background-color: #e4e6e8;
      }

      .column {
        flex: 0 1 30.333%;

        a {
          text-decoration: none;
        }

        .item {
          display: flex;
          flex-direction: column;
          cursor: pointer;
          position: relative;
          padding: 28px;
          transition: 0.3s all;
          color: #222222;

          .label {
            z-index: 5;
            font-weight: 500;
            font-size: 24px;
            line-height: 125%;
            flex: 1 1 auto;
          }

          .list {
            max-width: 300px;
            transition: 0.3s all;
            opacity: 0;
            pointer-events: none;
            margin: 8px 0 0 0;

            > * {
              margin: 4px 4px 4px 0;
            }

            .link {
              display: inline-block;
              font-weight: 700;
              font-size: 14px;
              line-height: 129%;
              padding: 8px 6px;
              background: rgba(255, 255, 255, 0.2);
              border-radius: 10px;
              color: #fff;
            }
          }

          .image {
            position: absolute;
            top: -130px;
            left: 0;

            img {
              border-radius: 20px;
              width: 347px;
              height: 322px;
              object-fit: cover;
            }
          }
        }

        .item.corporate.active,
        .item.legal.active,
        .item.accounting.active,
        .item.hr.active {
          border-radius: 20px;
          h2 {
            color: #fff;
          }
          .list {
            opacity: 1;
            pointer-events: all;
            color: #fff;
          }
        }
        .item.corporate,
        .item.legal,
        .item.accounting,
        .item.hr {
          height: 224px;
          &:hover {
            background-color: #60b3ff;
            color: #fff;
            border-radius: 20px;

            .list {
              opacity: 1;
              pointer-events: all;
            }
          }
        }

        .item.corporate.active {
          background-color: #44d078;
        }
        .item.corporate.transparentBackground {
          background-color: transparent !important;

          h2 {
            color: #222;
          }
          ul {
            display: none;
          }
        }
        .item.corporate {
          &:hover {
            background-color: #44d078;
          }
        }
        .item.legal.active {
          background-color: #60b3ff;
        }
        .item.legal {
          &:hover {
            background-color: #60b3ff;
          }
        }
        .item.members {
          position: relative;
          .label {
            position: absolute;
            left: 0;
            bottom: -265px;
            z-index: 5;
            width: 292px;
            height: 108px;
            background-color: #ff6c56;
            border-radius: 20px;
            color: #fff;
            padding: 24px 28px 36px 28px;

            transition: 0.3s all;

            &:hover {
              // height: 178px;
              height: 230px;

              p {
                opacity: 1;
              }
              .more {
                pointer-events: all;
                opacity: 1;
              }
            }

            h2 {
              font-weight: 500;
            }
            p {
              transition: 0.3s all;
              pointer-events: none;
              opacity: 0;
              font-size: 18px;
              font-weight: 400;
              line-height: 133%;
              color: #f5f6f8;
              margin: 8px 0 24px 0;
            }
            .more {
              transition: 0.3s all;
              pointer-events: none;
              opacity: 0;
              display: flex;
              flex-direction: column;
              a {
                text-align: center;
                background: rgba(255, 255, 255, 0.15);
                border-radius: 16px;
                padding: 16px 24px;
                font-weight: 400;
                font-size: 18px;
                line-height: 133%;
                color: #fff;
                text-decoration: none;
              }
            }
          }
        }
        .item.accounting.active {
          background-color: #ffb74a;
        }
        .item.accounting {
          &:hover {
            background-color: #ffb74a;
          }
        }
        .item.hr.active {
          background-color: #a293ff;
        }
        .item.hr {
          &:hover {
            background-color: #a293ff;
          }
        }
        .item.all {
          margin: 4px;
          border-top: 1px solid #e4e6e8;
          transform: translate(-8px, 60px);
          transition: 0.3s all;

          &:hover {
            border-radius: 20px;
            background-color: #fff;
          }

          .label {
            display: flex;
            align-items: center;
            justify-content: space-between;

            img {
              width: 18px;
              height: 18px;
              transform: rotate(-90deg);
            }
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .services {
    .content {
      padding: 0px 48px 48px 48px !important;
    }
  }
  .item {
    .image {
      img {
        max-width: 286px !important;
        max-height: 422px !important;
      }
    }
  }
  .item.members {
    .label {
      width: 230px !important;
      height: 278px !important;
      padding: 24px 28px 36px 28px !important;
      bottom: -263px !important;

      p {
        opacity: 1 !important;
      }
      .more {
        pointer-events: all !important;
        opacity: 1 !important;
      }
    }
  }

  .item.corporate,
  .item.legal,
  .item.accounting,
  .item.hr {
    height: 200px !important;
  }
  .item.all {
    border-top: none !important;
  }
}

@media (max-width: 901px) {
  .content {
    display: none;
  }
}
