.calculate {

    .questions {
        margin: 80px 0 36px 0;

        .label {
            margin: 188px 0 36px 0;
            transform: translate(0px, -80px);
        }

        .body {
            display: flex;
            justify-content: space-between;
            gap: 16px;

            .titleprice {
                text-align: center;
                font-weight: 700;
                font-size: 34px;
                line-height: 112%;
                letter-spacing: -0.01em;
                color: white;
                margin-bottom: 25px;
            }

            .left {
                flex: 1 1 48%;
            }

            .right {
                flex: 0 1 49%;
                max-height: 100% !important;
            }

            .left {


                .textblock {
                    display: flex;
                    flex-direction: column;
                    background-color: #ffb74a;
                    padding: 36px 28px;
                    border-radius: 20px;
                }

                .titletext {
                    font-size: 16px;
                    display: flex;
                    flex-direction: column;
                    margin: 0 0 25px 0;

                    p {
                        color: white;
                        font-weight: 700;
                    }

                    span {
                        color: rgb(255,255,255, 0.8);
                    }

                    .titledescription {
                        gap: 70px;
                        font-size: 22px;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                    }
                }

                .buttonsmonblock {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    .buttonmon {
                        font-size: 20px;
                        font-weight: 700;
                        border-radius: 0px 0 20px 0;
                        background-color: #f5f6f8;
                        color: #222222;
                        width: 24%;
                        padding: 28px;
                    }
                    /*.buttonmon:focus {
                        background-color: #60b3ff !important;
                        color: white !important;
                    }*/

                    .buttonmonfocus {
                        background-color: #60b3ff !important;
                        color: white !important;
                    }
                }

                .pricetext {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    background-color: rgba(255, 255, 255, 0.2);
                    padding: 12px 10px;
                    border-radius: 10px;


                    p {
                        line-height: 1.3;
                        font-size: 20px;
                        color: white;
                    }

                    span {
                        font-size: 30px;
                        font-weight: 700;
                        color: white;
                    }
                }

                .pricetext:not(:last-child) {
                    margin: 0 0 20px 0;
                }
            }

            .right {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;

                .sended {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    h2 {
                        margin: 24px 0 12px 0;
                    }

                    p {
                        max-width: 378px;
                        margin: 0 auto;
                        margin: 12px 0 24px 0;
                        font-size: 18px;
                        line-height: 133%;
                        text-align: center;
                        color: #6a6f74;
                    }

                    button {
                        background-color: #222 !important;
                    }
                }

                .form,
                .sended {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    max-width: 100%;
                    width: 100%;
                    padding: 36px 28px;
                    background-color: #f5f6f8;
                    border-radius: 20px;

                    h2 {
                        font-weight: 700;
                        font-size: 28px;
                        line-height: 121%;
                        letter-spacing: -0.01em;
                        text-align: center;
                        color: #222;
                    }

                    .text {
                        max-width: 445px;
                        margin: 0 auto;

                        p {
                            margin: 12px 0 24px 0;
                            font-size: 18px;
                            line-height: 133%;
                            text-align: center;
                            color: #6a6f74;
                        }
                    }
                }

                .checkboxgroup {
                    display: flex;
                    gap: 20px;
                    margin: 0px 0 15px 8px;

                    .castomcheckbox {
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        font-size: 16px;
                        position: relative;

                        input {
                            display: none;
                        }

                        span {
                            background-color: white;
                            width: 20px;
                            height: 20px;
                            border: 2px solid #ffb74a;
                            border-radius: 50%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-right: 8px;
                            transition: all 0.3s;
                        }
                    }

                    .castomcheckbox input:checked + span::after {
                        content: "✔";
                        color: #ffb74a;
                        font-size: 16px;
                    }
                }

                .fieldblock {
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    justify-content: space-between;

                    .fieldhalf {
                        width: 49%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;

                        input,
                        textarea,
                        select {
                            box-sizing: border-box;
                            width: 100%;
                            border-radius: 16px;
                            padding: 16px 10px 16px 28px;
                            font-size: 17px;
                            line-height: 141%;
                            outline: none;
                            border: 1px solid transparent;

                            &:focus {
                                border: 1px solid #9da0a4;
                            }
                        }

                        input:-webkit-autofill,
                        textarea:-webkit-autofill,
                        select:-webkit-autofill {
                            opacity: 0.99;
                            background-color: rgb(255, 183, 74, 0.2) !important;
                            box-shadow: 0 0 0px 1000px rgb(255, 183, 74, 0.2) inset !important;
                        }

                        select {
                            width: 100%;
                        }

                        textarea {
                            resize: none;
                            min-height: 120px;
                        }

                        strong {
                            color: #ffb74a;
                        }

                        p {
                            font-size: 16px;
                            color: #222222;
                            margin: 0px 0 15px 8px;
                        }

                        .description {
                            font-size: 15px !important;
                            color: #787c81 !important;
                            margin: 0px 0 15px 8px !important;
                        }
                    }
                }


                .fieldblockdisabled {
                    opacity: 0.5 !important;
                }

                .fieldblockdisabled input {
                    pointer-events: none;
                }

                .field {
                    input,
                    textarea,
                    select {
                        box-sizing: border-box;
                        width: 100%;
                        border-radius: 16px;
                        padding: 16px 10px 16px 28px;
                        font-size: 17px;
                        line-height: 141%;
                        outline: none;
                        border: 1px solid transparent;

                        &:focus {
                            border: 1px solid #9da0a4;
                        }
                    }

                    input:-webkit-autofill,
                    textarea:-webkit-autofill,
                    select:-webkit-autofill {
                        opacity: 0.99;
                        background-color: rgb(255, 183, 74, 0.2) !important;
                        box-shadow: 0 0 0px 1000px rgb(255, 183, 74, 0.2) inset !important;
                    }

                    textarea,
                    select {
                        box-sizing: border-box;
                        width: 100%;
                        border-radius: 16px;
                        padding: 16px 10px 16px 28px;
                        font-size: 17px;
                        line-height: 141%;
                        outline: none;
                        border: 1px solid transparent;

                        &:focus {
                            border: 1px solid #9da0a4;
                        }
                    }

                    textarea {
                        resize: none;
                        min-height: 120px;
                    }

                    p {
                        font-size: 16px;
                        color: #222222;
                        margin: 0px 0 15px 8px;
                    }

                    strong {
                        color: #ffb74a;
                    }

                    .description {
                        font-size: 15px !important;
                        color: #787c81 !important;
                        margin: 0px 0 15px 8px !important;
                    }
                }

                .field:not(:last-child) {
                    margin: 0 0 12px 0;
                }

                .field.incorrect {
                    input, select {
                        border: 1px solid #ff4f35;
                    }
                }

                .fieldblock:not(:last-child) {
                    margin: 0 0 12px 0;
                }

                .fieldblock.incorrect {
                    input, select {
                        border: 1px solid #ff4f35;
                    }
                }

                h3 {
                    font-size: 16px;
                    line-height: 137%;

                    span,
                    a {
                        text-decoration: none;
                        color: #6a6f74;
                        display: block;
                    }

                    a {
                        color: #222;
                    }
                }

                .submit,
                .sended button {
                    width: 100%;
                    margin: 24px 0 0 0;
                    display: flex;
                    justify-content: center;
                    background-color: #8c8c8d;
                    border-radius: 16px;
                    transition: 0.3s all;
                    cursor: pointer;
                    padding: 16px;
                    color: #fff;
                    font-size: 18px;
                    line-height: 133%;
                }

                .submit.fullfilled {
                    background-color: #222;
                }
            }
        }
    }
}

@media (max-width: 992px) {
    .field, .fieldhalf
    {
        p{
            font-size: 16px !important;
        }
    }

    .questions {
        margin: 60px 0 36px 0 !important;

        .label {
            margin: 60px 0 36px 0 !important;
        }
    }

    .right, .left {
        .form {
            padding: 20px 16px !important;
        }
    }

    .body {
        flex-wrap: wrap;

        .left,
        .right {
            flex: 1 1 100% !important;
            width: 100%;
        }

        .form {
            max-width: 100% !important;
            width: 100% !important;
        }
    }
}

@media (max-width: 958px) {
    .titleprice {
        font-size: 28px !important;
    }
}

@media (max-width: 810px) {
    .body {
        flex-wrap: wrap;

        .titletext {
            flex-direction: column !important;
            justify-content: unset !important;
        }
    }
}

@media (max-width: 479px) {
    .questions {
        margin: 40px 0 16px 0 !important;

        .label {
            margin: 40px 0 16px 0 !important;
        }
    }

    .right {
        h2 {
            font-size: 25px !important;
        }
    }
}


