.info {
  margin: -25px 0 80px 0;
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }

  .body.corporate {
    background-color: #44d078;
  }
  .body.legal {
    background-color: #60b3ff;
  }
  .body.accounting {
    background-color: #ffb74a;
  }
  .body.hr {
    background-color: #a293ff;
  }

  .body {
    border-radius: 24px;
    padding: 48px;
    max-height: 260px;

    display: flex;
    gap: 16px;
    justify-content: space-between;
    align-items: center;

    .left {
      max-width: 524px;
      color: #fff;
      .title {
        font-weight: 700;
        font-size: 40px;
        line-height: 120%;
        letter-spacing: -0.01em;
      }
      .text {
        margin: 8px 0 12px 0;
        font-size: 18px;
        line-height: 133%;
      }
      .complex,
      .other {
        cursor: pointer;
        transition: 0.3s all;
      }
      .complex {
        background-color: #fff;
      }
      .other {
        background: rgba(255, 255, 255, 0.15);
        color: #fff;
      }
      a {
        border-radius: 11px;
        display: inline-block;
        margin: 4px;
        padding: 8px 12px;
        text-decoration: none;
        color: #000;
        font-size: 18px;
        line-height: 133%;
      }
      a:nth-child(6) {
        font-size: 17px;
      }
      .buttons {
        display: flex;
        gap: 8px;
      }
    }
    .right {
      transform: translateY(28px);
      svg {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

@media (max-width: 992px) {
  .body {
    padding: 40px 32px !important;
  }
  .info {
    margin: -35px 0 60px 0 !important;
  }
  .left {
    max-width: 439px !important;
  }
  .right {
    transform: translateY(30px) !important;
  }
}

@media (max-width: 900px) {
  .container {
    padding: 0 !important;
  }
  .left {
    max-width: 344px !important;
    .title {
      font-size: 28px !important;
    }
    .text {
      font-size: 16px !important;
    }
  }
}

@media (max-width: 767px) {
  .info {
    margin: -35px 0 40px 0 !important;
  }
  .body {
    flex-wrap: wrap;
    justify-content: flex-start !important;
    gap: 0 !important;
    max-height: 100% !important;
    padding: 32px 16px !important;

    .left {
      .title {
        margin: 0 0 8px 0;
      }
      .text {
        display: none;
      }
    }

    .right {
      transform: translateY(32px) !important;

      svg {
        width: 100% !important;
        height: 100% !important;
        object-fit: cover !important;
      }
    }
  }
}

@media (max-width: 479px) {
  .left {
    a {
      font-size: 16px !important;
    }
  }
}
