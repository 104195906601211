* {
  padding: 0;
  margin: 0;
  border: 0;
  user-select: none;
}
*,
*:before *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
:focus :active {
  outline: none;
}
a:focus,
a:active {
  outline: none;
}
nav,
footer,
header,
aside {
  display: block;
}
html,
body {
  height: 100%;
  width: 100%;
  line-height: 1;
  font-size: 14px;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-family: "Lab Grotesque";
  background-color: #fff;
}
body.lock {
  overflow: hidden;
}
input,
button,
textarea {
  font-family: inherit;
}
input:-ms-clear {
  display: none;
}
button:-moz-focus-inner {
  padding: 0;
  border: 0;
}
button {
  cursor: pointer;
}
a a:visited {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
ul li {
  list-style: none;
}
img {
  vertical-align: top;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: 400;
}

@font-face {
  font-family: "Lab Grotesque";
  font-style: normal;
  font-weight: 400;
  src: url(../public/fonts/LabGrotesque-Regular.ttf);
}
@font-face {
  font-family: "Lab Grotesque";
  font-style: normal;
  font-weight: 500;
  src: url(../public/fonts/LabGrotesque-Medium.ttf);
}
@font-face {
  font-family: "Lab Grotesque";
  font-style: normal;
  font-weight: 700;
  src: url(../public/fonts/LabGrotesque-Bold.ttf);
}

/* Styles */

.wrapper {
  min-height: 100vh;
  overflow: hidden;

  display: flex;
  flex-direction: column;
}
main {
  flex: 1 1 100%;
  margin: 140px 0 0 0;
}

[class*="container"] {
  margin: 0 auto;
  padding: 0 20px;
  max-width: 1160px;
}

.title {
  font-weight: 700;
  font-size: 44px;
  line-height: 114%;
  letter-spacing: -0.01em;
}
.sub-title {
  font-weight: 700;
  font-size: 34px;
  line-height: 112%;
  letter-spacing: -0.01em;
  color: #222;
}

@media (min-width: 1201px) {
  main.header-menu-active {
    transform: translateX(-8px);
  }
}
@media (max-width: 992px) {
  .title {
    font-size: 36px;
  }
}
@media (max-width: 767.98px) {
  .sub-title {
    font-size: 25px !important;
  }
}
@media (max-width: 679px) {
  .title {
    font-size: 30px;
  }
  [class*="container"] {
    padding: 0 15px !important;
  }
}
