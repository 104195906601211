.tabletContent {
  display: none;
  background-color: #f5f6f8;
  border-radius: 24px;
  padding: 40px 24px;
  margin: -40px -20px;
}
@media (max-width: 901px) {
  .item.members {
    position: relative;
    .label {
      position: absolute;
      left: 0;
      bottom: -357px;
      z-index: 5;
      width: 291px !important;
      height: 108px;
      // padding: 24px 28px 36px 28px !important;
      padding: 24px 28px 120px 28px !important;
      background-color: #ff6c56;
      border-radius: 20px;
      color: #fff;
      height: 178px !important;
      h2 {
        font-weight: 500;
      }
      p {
        opacity: 1 !important;
        font-size: 18px;
        font-weight: 400;
        line-height: 133%;
        color: #f5f6f8;
        margin: 8px 0 24px 0;
      }
      .more {
        pointer-events: all !important;
        opacity: 1 !important;
        display: flex;
        flex-direction: column;

        a {
          text-align: center;
          background: rgba(255, 255, 255, 0.15);
          border-radius: 16px;
          padding: 16px 24px;
          font-weight: 400;
          font-size: 18px;
          line-height: 133%;
          color: #fff;
          text-decoration: none;
        }
      }
    }
  }

  .tabletContent {
    display: flex !important;
    gap: 8px;
    position: relative;

    a {
      text-decoration: none;
    }

    .left {
      flex: 0 1 50%;

      .title {
        font-weight: 700;
        font-size: 35px;
        line-height: 103%;
        letter-spacing: -0.01em;
        color: #222222;
        margin: 0 0 36px 0;
      }
    }

    .item.corporate.active,
    .item.legal.active,
    .item.accounting.active,
    .item.hr.active {
      border-radius: 20px;
      color: #fff;
      border-radius: 20px;
      .list {
        opacity: 1;
        pointer-events: all;
      }
    }

    .item.corporate.active {
      background-color: #44d078;
    }
    .item.accounting.active {
      background-color: #ffb74a;
    }
    .item.accounting {
      border-top: 1px solid #e4e6e8;
    }
    .item.legal.active {
      background-color: #60b3ff;
    }
    .item.legal {
      border-top: 1px solid #e4e6e8;
    }
    .item.hr.active {
      background-color: #a293ff;
    }
    .item.hr {
      position: absolute;
      top: 470px;
      width: 292px;
    }
    .item.all {
      border-top: 1px solid #e4e6e8;
      position: absolute;
      top: 650px;

      .label {
        display: flex;
        align-items: center;
        gap: 30px;

        img {
          width: 18px;
          transform: rotate(-90deg);
        }
      }
    }

    .item {
      display: flex;
      flex-direction: column;
      cursor: pointer;
      position: relative;
      transition: 0.3s all;
      color: #222222;
      padding: 28px;
      margin: 0 8px 8px 0;

      .image {
        position: absolute;
        top: -10px;
        left: 0;
        img {
          border-radius: 20px;
          width: 347px;
          height: 312px;
          object-fit: cover;
        }
      }

      .label {
        z-index: 5;
        font-weight: 500;
        font-size: 24px;
        line-height: 125%;
        flex: 1 1 auto;
      }

      .list {
        transition: 0.3s all;
        opacity: 0;
        pointer-events: none;
        margin: 8px 0 0 0;

        > * {
          margin: 4px 4px 4px 0;
        }

        .link {
          display: inline-block;
          font-weight: 700;
          font-size: 14px;
          line-height: 129%;
          padding: 8px 6px;
          background: rgba(255, 255, 255, 0.2);
          border-radius: 10px;
          color: #fff;
        }
      }
    }

    .tabletDivider {
      position: absolute;
      background-color: #e4e6e8;
      height: 93%;
      width: 1px;
      left: 50%;
    }
  }
}

@media (max-width: 752px) {
  .item {
    .image {
      img {
        width: 283.5px !important;
      }
    }
  }
  .item.members {
    position: relative;
    .label {
      width: 228px !important;
    }
  }
  .item.hr {
    width: 229px !important;
  }
}

@media (max-width: 622px) {
  .tabletContent {
    display: none !important;
  }
}
