.offers {
  margin: -32px 0 80px 0;
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 15px 20px;
  }

  .content {
    background-color: #f5f6f8;
    border-radius: 24px;
    padding: 48px;
  }

  .label {
    margin: 0 0 36px 0;
  }
  .body {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    position: relative;

    .column {
      flex: 0 1 30.333%;

      .item {
        &.members {
          .image {
            width: 344px;
            height: 370px;
            img {
              border-radius: 20px;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .info {
            transform: translateY(-33px);
            border-radius: 20px;
            background-color: #ff6c56;
            color: #fff;
            padding: 24px 28px;
            max-height: 120px;
            transition: 0.3s all;

            p,
            .more {
              transition: 0.3s all;
              opacity: 0;
              pointer-events: none;
            }

            &:hover {
              transform: translateY(-118px);
              max-height: 1000px;
              p,
              .more {
                opacity: 1;
                pointer-events: all;
              }
            }

            h2 {
              font-weight: 500;
              font-size: 24px;
              line-height: 125%;
              letter-spacing: -0.01em;
              color: #fff;
            }
            p {
              margin: 8px 0 24px 0;
              font-size: 18px;
              line-height: 133%;
              color: #f5f6f8;
            }
            .more {
              cursor: pointer;
              background: rgba(255, 255, 255, 0.15);
              padding: 16px 15px;
              border-radius: 16px;
              display: flex;
              justify-content: center;

              font-size: 18px;
              line-height: 133%;
              color: #fff;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1023.98px) {
  .item {
    &.members {
      .info {
        max-height: 1000px !important;
      }
      p,
      .more {
        opacity: 1 !important;
        pointer-events: all !important;
      }
    }
  }
}

@media (max-width: 992px) {
  .offers {
    margin: -15px 0 60px 0 !important;
  }

  .content {
    padding: 40px 32px !important;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 15px 0 !important;
  }
}

@media (max-width: 479px) {
  .offers {
    margin: -15px 0 40px 0 !important;
  }
  .content {
    padding: 32px 16px !important;
  }
  .item {
    &.members {
      .image {
        width: 328px !important;
      }
    }
  }
}
