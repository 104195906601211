.block {
  margin: 64px 0 128px 0;

  .row {
    display: flex;
    gap: 16px;

    .divider {
      display: flex;
      gap: 16px;
    }

    .buttonsTablet {
      margin: 0 0 0 55px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      border-right: 1px solid #e4e6e8;

      .option {
        width: 285px;
        display: flex;
        align-items: center;
        gap: 8px;
        background-color: transparent;
        font-size: 18px;
        line-height: 133%;
        padding: 16px 0;
        position: relative;

        &:hover,
        &.active {
          background-color: #f5f6f8;
        }
        &.active {
          &:after {
            content: "";
            position: absolute;
            right: -1px;
            width: 2px;
            height: 100%;
            background-color: #2798ff;
          }
        }

        img {
          width: 26px;
          height: 26px;
        }
      }
    }
    .services {
      li {
        width: 285px;
        padding: 8px 0;
      }
      a {
        width: 285px;
        font-size: 16px;
        line-height: 137%;
        color: #6a6f74;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
          color: #222;
        }
      }
    }
  }

  .mobileRow {
    display: none;
  }
}

@media (max-width: 992px) {
  .block {
    margin: 56px 0 100px 0;
  }
}

@media (max-width: 910px) {
  .services {
    li {
      width: 225px !important;
    }
  }
}

@media (max-width: 767px) {
  .block {
    margin: 0 0 40px 0 !important;
  }
  .row {
    display: none !important;
  }
  .mobileRow {
    display: block !important;

    overflow-y: auto;

    .mobileTitle {
      font-weight: 700;
      font-size: 30px;
      line-height: 113%;
      letter-spacing: -0.01em;
      margin: 0 0 36px 0;
    }

    .hr {
      width: 100%;
      height: 1px;
      background-color: #e4e6e8;
      margin: 8px 0;
    }

    .dropdowns {
      .dropdown {
        display: flex;
        flex-direction: column;
        .dropdownBtn {
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          padding: 8px 0;
          // border-bottom: 1px solid #e4e6e8;

          h2 {
            img {
              width: 26px;
            }
            display: flex;
            align-items: center;
            gap: 8px;
            font-size: 18px;
            line-height: 133%;
          }

          .arrow {
            transition: all 0.3s;
          }
          .arrow.active {
            transform: rotate(180deg);
          }
        }

        .dropdownContent {
          padding: 0 0 0 35px;

          li {
            padding: 8px 0;
            a {
              text-decoration: none;
              padding: 0 !important;
              font-size: 16px;
              line-height: 137%;
              color: #6a6f74;
            }
          }
        }

        .buttons {
          display: flex;
          flex-direction: column;
          gap: 24px;
        }
        .hiddenConsult {
          background-color: #f5f6f8;
        }
        .hiddenPersonal {
          background-color: #222222;
          color: #fff;
        }
        .hiddenConsult,
        .hiddenPersonal {
          padding: 16px;
          border-radius: 16px;
          font-size: 18px;
          line-height: 133%;
        }
      }
    }
  }
}
