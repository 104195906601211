.dropdown.green,
.dropdown.blue {
  border-radius: 16px;

  .dropdownBtn {
    color: #fff;
    font-weight: 500;
    font-size: 21px;
    line-height: 114%;
    letter-spacing: -0.01em;
  }
  .dropdownContent {
    color: #fff;
  }
}
.dropdown.green {
  background-color: #44d078;
}
.dropdown.blue {
  background-color: #60b3ff;
}
.dropdown.gray {
  border-radius: 20px;
  background-color: #f5f6f8;
  padding: 10px 28px !important;

  .dropdownBtn.active {
    transform: translateY(0);

    svg {
      transform: rotate(-90deg) translateX(0px);
    }
  }

  .dropdownBtn {
    margin: 0 0 12px 0 !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    h2 {
      max-width: 100% !important;
    }

    svg {
      background-color: #fff;
      transform: rotate(90deg);
      position: relative;
      color: #fff;
      padding: 10px 4px;
      border-radius: 50%;

      path {
        stroke: #222;
      }

      &:hover {
        background-color: #222;

        path {
          color: #222;
          stroke: #fff;
        }
      }
    }
  }

  .dropdownContent {
    max-width: 450px;
    font-size: 18px;
    line-height: 133%;
    color: #6a6f74;

    a {
      color: #6a6f74;
    }
  }
}
.dropdown.white {
  background-color: #fff;
  border-radius: 16px;

  h2 {
    max-width: 380px !important;
  }
  li {
    line-height: 137%;
    font-size: 15px;
  }
}

.dropdown.modalWhite,
.modalTable {
  h2 {
    max-width: 100% !important;
  }
  @media (max-width: 712px) {
    h2 {
      max-width: 260px !important;
    }
  }
  .dropdownBtn {
    font-size: 20px !important;
    line-height: 137%;
    color: #222;
    padding: 0 0 8px 0;
  }
  .dropdownContent {
    // width: 100vw;

    .dropdownItem {
      // max-width: 330px;

      padding: 15px 15px 15px 15px;
      // margin: -16px -0px -0px -26px;
      margin: -16px -0px -0px -32px;
      background-color: #fff;
      font-size: 16px !important;
      line-height: 137%;
      color: #6a6f74;
      position: relative;
      z-index: 2;
    }
  }

  .modalBody {
    .modalRow {
      display: flex;
      max-width: 1000px;
      background-color: #fff;
      padding: 15px 15px 15px 15px;
      margin: -16px -0px -0px -32px;

      .left,
      .right {
        font-size: 17px;
        line-height: 129%;
        padding: 8px;
        border-bottom: 1px solid #e4e6e8;
      }
      .left {
        flex: 1 1 80%;
        border-right: 1px solid #e4e6e8;

        span {
          color: #6a6f74;
        }
      }
      .right {
        flex: 1 1 20%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 0 0 25px;
      }
    }
    @media (max-width: 712px) {
      .modalRow {
        max-width: 320px !important;
        .left,
        .right {
          font-size: 14px !important;
        }
      }
    }
  }
}

.dropdown.modalSpecial {
  h2 {
    max-width: 100% !important;
  }
  ul {
    max-width: 1000px !important;
    // width: 100%;
    transform: translateX(-44px);
  }
  li {
    font-size: 16px;
    line-height: 137%;
    color: #6a6f74;
    background-color: #fff;
    padding-left: 50px;
  }
}

@media (max-width: 992px) {
  .dropdown.modalSpecial {
    ul {
      width: 888px;
      transform: translateX(-44px);
    }
  }
}

@media (max-width: 768px) {
  .dropdown.modalSpecial {
    ul {
      width: 600px;
      transform: translateX(-32px);
    }
    li {
      width: 100%;
      padding: 0 40px 0 40px;
    }
  }
}

@media (max-width: 479px) {
  .dropdown.white {
    h2 {
      max-width: 260px !important;
    }
  }
}
.dropdown.regular {
  border-top: 1px solid #e4e6e8;
}

.dropdown {
  padding: 20px 16px;

  .dropdownBtn.active {
    transform: translateY(0);

    svg {
      transform: rotate(-90deg) translateX(8px);
    }
  }
  .dropdownBtn {
    margin: 0 0 8px 0;
    transition: all 0.3s;
    cursor: pointer;
    transform: translateY(3px);
    font-size: 20px;
    line-height: 120%;
    letter-spacing: -0.01em;
    color: #222;
    position: relative;

    h2 {
      max-width: 260px;
    }

    svg {
      transition: all 0.3s;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -10px;
      min-width: 30px;
    }
  }
  .dropdownContent.active {
    // !!!
    // max-height: 400px;
    max-height: 100%;
    overflow: visible;
    opacity: 1;
    pointer-events: all;
  }
  .dropdownContent {
    transition: all 0.3s;
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    pointer-events: none;
    .dropdownItem {
    }
  }
}

@media (max-width: 992px) {
  .dropdown.gray {
    .dropdownContent {
      max-width: 260px !important;
      font-size: 16px !important;
    }
  }
}

@media (max-width: 479px) {
  .dropdown.gray {
    padding: 10px 14px !important;
  }
}
