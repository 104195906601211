.policy {
  .goBack {
    margin: 0 0 20px 0;
    button {
      background-color: transparent;
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 18px;
      line-height: 133%;
      color: #6a6f74;
      cursor: pointer;
    }
  }

  .body {
    .item {
      .subTitle {
        margin: 36px 0;
        font-weight: 700;
        font-size: 28px;
        line-height: 121%;
        letter-spacing: -0.01em;
        color: #222;
      }

      .name {
        font-weight: 700;
        font-size: 28px;
        line-height: 121%;
        letter-spacing: -0.01em;
        color: #222;
      }

      .text,
      .link {
        margin: 24px 0 0 0;
        max-width: 1064px;
        p,
        li {
          font-size: 18px;
          line-height: 133%;
          color: #6a6f74;
        }
        li {
          font-size: 16px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .body {
    .item {
      .subTitle {
        font-size: 25px !important;
      }
    }
  }
}
