.mobileContent {
  display: none;
  background-color: #f5f6f8;
  border-radius: 24px;
}
@media (max-width: 622px) {
  a {
    text-decoration: none;
  }
  .mobileContent {
    display: block !important;
    max-height: 670px;

    .title {
      padding: 24px 16px 0 16px;
      font-weight: 700;
      font-size: 32px;
      line-height: 112%;
      letter-spacing: -0.01em;
      color: #222;
      margin: 0 0 16px 0;
    }

    .body {
      padding: 0 16px 0 0;
      display: flex;
      align-items: first baseline;
      overflow-y: auto;

      .item:nth-child(1) {
        padding: 0 0 0 16px;
        margin: 0 8px 0 0;

        .info {
          background-color: #44d078;

          .list {
            .link:nth-child(3) {
              width: 190px;
            }
          }
        }
      }
      .item:nth-child(2) {
        .info {
          background-color: #60b3ff;
          transform: translateY(-62.5px);
        }
      }
      .item:nth-child(3) {
        margin: 0 10px 0 0;
        .info {
          background-color: #ffb74a;
          transform: translateY(-24px);

          .link {
            padding: 8px 4px !important;
          }
        }
      }
      .item:nth-child(4) {
        margin: 0 15px 0 0;
        .info {
          background-color: #a293ff;
          transform: translateY(-25px);
        }
      }
      .item:nth-child(5) {
        .info {
          background-color: #ff6c56;
          display: flex;
          flex-direction: column;
          transform: translateY(-147px);

          .label {
            flex: 0 0 0%;
          }
          p {
            flex: 1 1 auto;
            margin: 4px 0 12px 0;
          }
        }
      }
      .item {
        min-width: 280px;
        .image {
          img {
            border-radius: 16px;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .info {
          display: flex;
          flex-direction: column;
          transform: translateY(-80px);
          border-radius: 16px;
          color: #fff;
          padding: 20px 16px;
          .label {
            font-weight: 500;
            font-size: 21px;
            line-height: 114%;
            letter-spacing: -0.01em;
            margin: 0 0 24px 0;
          }

          p {
            font-weight: 400;
            font-size: 16px;
            line-height: 137%;
          }

          .more {
            display: flex;
            flex-direction: column;

            a {
              text-align: center;
              background: rgba(255, 255, 255, 0.15);
              border-radius: 16px;
              padding: 16px 24px;
              font-weight: 400;
              font-size: 18px;
              line-height: 133%;
              color: #fff;
              text-decoration: none;
            }
          }

          .list {
            > *:not(:last-child) {
              margin: 0 0 4px 0;
            }

            .link {
              display: inline-block;
              font-weight: 700;
              font-size: 14px;
              line-height: 129%;
              padding: 8px 6px;
              background: rgba(255, 255, 255, 0.2);
              border-radius: 10px;
            }
          }
        }
      }
    }
    .all {
      background-color: #fff;
      height: 64px;
      border-radius: 16px;
      padding: 0 16px;
      margin: 0 10px;
      transform: translateY(-95px);
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        font-weight: 500;
        font-size: 21px;
        line-height: 114%;
        letter-spacing: -0.01em;
        color: #222;
        text-decoration: none;
      }
    }
  }
}
