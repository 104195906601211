.container {
  max-width: 1200px;
  padding: 15px 20px;
  margin: 0 auto;
}

.rate {
  .row {
    padding: 48px;
    background-color: #f5f6f8;
    border-radius: 24px;
  }
  .top {
    display: flex;
    justify-content: space-between;

    .label {
      margin: 0 0 36px 0;
    }

    .arrows {
      display: none;
      align-items: start;
      gap: 16px;

      .arrowLeft {
        transform: rotate(-180deg);
        opacity: 0.2;
      }
      .arrowRight {
        transition: 0.3s all;
      }

      svg {
        cursor: pointer;
        transition: 0.3s all;
        color: #222;

        rect {
          transition: 0.3s all;
          fill: #f2f3f4;
        }
        path {
          transition: 0.3s all;
          fill: none;
        }

        &:hover {
          color: #fff;
          rect {
            fill: #222;
          }
        }
      }
      .arrowLeft.active {
        transition: 0.3s all;
        opacity: 1;
        rect {
          fill: #f2f3f4;
        }
      }
      .arrowLeft.active + .arrowRight {
        transition: 0.3s all;
        opacity: 0.2 !important;
        color: red;
        rect {
          fill: #f2f3f4;
        }
      }
    }
  }
  .body {
    transition: 0.3s all;
    display: flex;
    gap: 16px;

    .column:nth-child(2) {
      .more {
        transform: translateY(19px);
      }
    }
    .column {
      cursor: pointer;
      display: flex;
      min-width: 254px;
      border: 2px solid transparent;
      border-radius: 20px;
      transition: 0.3s all;

      &:hover,
      &.active {
        border: 2px solid #ffb74a;
        box-shadow: 0 4px 15px 0 rgba(95, 105, 131, 0.12), 0 5px 4px 0 rgba(95, 105, 131, 0.05),
          0 0 4px 0 rgba(95, 105, 131, 0.07);
      }

      .item {
        padding: 28px;
        border-radius: 20px;
        background-color: #fff;

        .label {
          font-weight: 500;
          font-size: 21px;
          line-height: 114%;
          letter-spacing: -0.01em;
          color: #222;
        }
        .labelDescription {
          display: none;
        }
        .labelDescription,
        .labelDescriptionDesktop {
          margin: 4px 0 12px 0;
          font-size: 15px;
          line-height: 137%;
          color: #6a6f74;
        }
        .price {
          margin: 0 0 12px 0;
          font-size: 14px;
          line-height: 129%;
          color: #6a6f74;
          span {
            font-weight: 700;
            font-size: 28px;
            line-height: 121%;
            letter-spacing: -0.01em;
            color: #222;
          }
        }
        .buy,
        .compare {
          transition: 0.3s all;
          background-color: transparent;
          font-size: 18px;
          line-height: 133%;
          color: #222;
          padding: 12px 10px;
          width: 100%;
          margin: 0 0 12px 0;
          border: 1px solid #222;
          border-radius: 20px;

          &:hover:not(.compare),
          &:focus:not(.compare) {
            color: #fff;
            background-color: #222;
          }
        }
        .compare {
          background-color: #f5f6f8;
          border: 1px solid transparent;
        }
        hr {
          background-color: #e4e6e8;
          width: 100%;
          height: 1px;
          margin: 0 0 8px 0;
        }
        .list {
          > *:not(:last-child) {
            margin: 0 0 12px 0;
          }
          li.none {
            color: #6a6f74 !important;
          }
          li {
            display: flex;
            gap: 4px;
            align-items: center;
            font-size: 14px;
            line-height: 129%;
            color: #222;
          }
        }
        .description {
          margin: 16px 0;
          line-height: 129%;
        }
        .more {
          margin: 38px 0 0 0;
          button {
            background-color: transparent;
            font-size: 18px;
            line-height: 133%;
            color: #2798ff;
          }
        }
      }
    }
  }
}

@media (max-width: 1128px) {
  .top {
    .arrows {
      display: flex !important;
    }
  }
  .body.slide {
    transition: 0.3s all;
    transform: translateX(-100px);
  }
}

@media (max-width: 1054px) {
  .body.slide {
    transform: translateX(-180px) !important;
  }
}

@media (max-width: 992px) {
  .row {
    padding: 32px !important;
  }
  .body.slide {
    transform: translateX(-190px) !important;
  }
}
@media (max-width: 991px) {
  .top {
    .arrows {
      display: none !important;
    }
  }
  .body {
    flex-wrap: wrap;

    .column:nth-child(2) {
      .more {
        transform: translateY(0px) !important;
      }
    }

    .column {
      flex: 1 1 48% !important;
      flex-direction: column;
      min-height: 100%;

      .item {
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
      }
      .labelDescription {
        display: block !important;
        max-width: 312px;
      }
      .compare,
      .more {
        margin: 0 !important;
      }
      .compare {
        margin: 0 0 38px 0 !important;
      }

      .more {
        position: absolute;
        bottom: 25px;
        left: 28px;
      }

      .labelDescriptionDesktop,
      hr,
      .list,
      .description {
        display: none;
      }
    }
  }
}

@media (max-width: 768px) {
  .container {
    padding: 15px 0 !important;
  }
  .labelDescription {
    margin: 4px 0 8px 0 !important;
  }
}

@media (max-width: 767.98px) {
  .row {
    padding: 16px !important;
  }
  .body {
    .column {
      flex: 1 1 100% !important;
      flex-direction: column;

      .item {
        padding: 16px 16px 38px 16px !important;

        .more {
          bottom: 15px !important;
        }
      }
    }
  }
}

@media (max-width: 479px) {
  .row {
    .top {
      .label {
        margin: 0 0 16px 0 !important;
      }
    }
  }
}
